.App {
}

.mountain-bg {
  background-image: url(images/mountain.jpg);
  background-attachment: fixed;
  background-color: white;
  background-size: cover;
}

.login-page {
  display: flex;
  align-items: center;
  min-height: 100vh;
}

.invisible {
  display: none;
}

.layout-msg-container {
  background-color: rgba(228, 228, 228, 0.9);
  min-height: 100vh;
  padding-top: 25vh;
}

.registration-form-container {
  background-color: rgba(228, 228, 228, 0.9);
  min-height: 100vh;
}
